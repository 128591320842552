import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const Gallery2023 = () => {
  return (
    <Box>
      <Stack
        spacing={4}
        style={{ padding: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h3" style={{ textAlign: "center" }}>
          GALERIA CORETIC CORESEB
        </Typography>
        <ImageList cols={2}>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+14.03.54+(10).jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+14.03.54+(9).jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+14.03.54+(8).jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+14.03.54+(7).jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+14.03.54+(6).jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+14.03.54+(4).jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+14.03.54.jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+16.22.16.jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+16.23.11.jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-05-22+at+16.23.27.jpeg`}
              alt="galeria-img"
              loading="lazy"
            />
          </ImageListItem>
        </ImageList>
      </Stack>
    </Box>
  );
};

export default Gallery2023;
