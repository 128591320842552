import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import CustomMenuList from "./Menu";
import { Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";

import "./style.css";
const pages = [
  {
    label: "Inicio",
    href: "/",
  },
  {
    label: "Patrocinio",
    href: "/sponsorship",
  },
  {
    label: "Galeria",
    href: "/gallery",
  },
  {
    label: "Guatemala",
    href: "/gt",
    date: "10/06 - 11/06",
  },
  {
    label: "Honduras",
    href: "/hn",
    date: "21/08",
  },
  {
    label: "El Salvador",
    href: "/sv",
    date: "04/09",
  },
  {
    label: "Costa Rica",
    href: "/cr",
    date: "22/10",
  },
];
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Layout(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar
        style={{
          backgroundColor: "white",
          color: "#1e3d52",
        }}
      >
        <Toolbar disableGutters>
          <Stack spacing={2} style={{ width: "100%" }}>
            <Accordion
              style={{
                width: "100%",
                backgroundColor: "#0E1025",
                color: "#D0CFD4",
                textAlign: "center",
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon style={{ color: "#D0CFD4" }} />}
              >
                <Typography>ABG-EBG-EBC-UC</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <Grid xs={12} md={12} lg={6} style={{ textAlign: "center" }}>
                    <Stack spacing={2} style={{ textAlign: "center" }}>
                      <Typography>Escuela Bancaria de Guatemala</Typography>
                      <Stack
                        direction="row"
                        spacing={3}
                        style={{ textAlign: "center" }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          style={{ color: "white" }}
                          onClick={() => {
                            window.open(
                              "https://abg.org.gt/es/quienes-somos/",
                              "_blank"
                            );
                          }}
                        >
                          Acerca De
                        </Button>
                        <Button
                          style={{ color: "white" }}
                          onClick={() => {
                            window.open(
                              "https://ebg.edu.gt/inscribete-en-linea",
                              "_blank"
                            );
                          }}
                        >
                          Ver Agenda Mensual
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid xs={12} md={12} lg={6} style={{ textAlign: "justify" }}>
                    <Typography variant="p" style={{ textAlign: "justify" }}>
                      Este sitio es operado por la Asociación Bancaria de
                      Guatemala y sus marcas educativas y todos los derechos de
                      autor residen con dichas entidades.
                      <br />
                      La Asociación Bancaria de Guatmala se encuentra en
                      Diagonal 6, 10-01 zona 10, Centro Gerencial Las
                      Margaritas, Torre II, 5º. Nivel, Oficina 502 “A”
                      Guatemala. PBX: (502) 2382-7200
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Grid container justifyContent="center">
              <Grid xs={3} md={3} lg={3} style={{ textAlign: "right" }}>
                <img
                  src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/CORETIC+2024+(1).png"
                  loading="lazy"
                  className="img-header"
                />
              </Grid>
              <Grid xs={3} md={3} lg={3} style={{ textAlign: "left" }}>
                <img
                  src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/CORESEB+2024+(1).png"
                  loading="lazy"
                  className="img-header"
                />
              </Grid>
              <Grid xs={3} md={3} lg={3} style={{ textAlign: "left" }}></Grid>
              <Grid xs={3} md={3} lg={3} style={{ textAlign: "center" }}>
                <img
                  src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/Logotipos+Finales+ABG+2022-01.png"
                  loading="lazy"
                  className="img-header"
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                backgroundColor: "#292F3B",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                style={{ color: "white" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem
                    key={`MENU-ITEM-${index}`}
                    onClick={() => {
                      window.location.href = page.href;
                    }}
                  >
                    <ListItemText
                      style={{
                        color: "#1e3d52",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {page.label}
                      {page.date && <br />}
                      <strong>{page.date}</strong>
                    </ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                backgroundColor: "#292F3B",
                height: "100px",
              }}
              justifyContent="center"
              alignItems={"center"}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={`MENU-ITEM-${index}`}
                  onClick={handleCloseNavMenu}
                >
                  <CustomMenuList
                    title={page.label}
                    date={page.date}
                    href={page.href}
                  />
                </MenuItem>
              ))}
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Box sx={{ paddingTop: { lg: "20%", md: "27%", xs: "43%" } }}>
        <Outlet />
      </Box>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
