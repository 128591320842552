import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Inicio from "./components/inicio/Inicio";
import Programa from "./components/programa/Programa";
import Registro from "./components/registro/Registro";
import Patrocinio from "./components/patrocinio/Patrocinio";
import Tarifas from "./components/tarifas/Tarifas";
import Contacto from "./components/contacto/Contacto";
import Galeria from "./components/galeria/Galeria";
import Talleres from "./components/talleres/Talleres";
import GT from "./components/GT/GT";
import HN from "./components/HN/HN";
import SV from "./components/SV/SV";
import CR from "./components/CR/CR";
import Gallery from "./components/gallery/Gallery";
function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Inicio />} />
          <Route path="/programa" element={<Programa />} />
          <Route path="/registro" element={<Registro />} />
          <Route path="/patrocinio" element={<Patrocinio />} />
          <Route path="/tarifas" element={<Tarifas />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/galeria" element={<Galeria />} />
          <Route path="/talleres" element={<Talleres />} />
          <Route path="/sponsorship" element={<Patrocinio />} />
          <Route path="/gt" element={<GT />} />
          <Route path="/hn" element={<HN />} />
          <Route path="/sv" element={<SV />} />
          <Route path="/cr" element={<CR />} />
          <Route path="/gallery" element={<Gallery />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
