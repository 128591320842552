import React from "react";
import View from "../evento/View";
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";

const CR = () => {
  return (
    <Stack spacing={5}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <Typography variant="h4">HOTEL BARCELÓ SAN JOSÉ</Typography>
          <Typography variant="h5">Salón el Greco</Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4661.593823327142!2d-84.10776882430281!3d9.945458673969481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0fb538af9f827%3A0x189287ab79edf6c2!2zQmFyY2Vsw7MgU2FuIEpvc8Op!5e1!3m2!1ses!2sgt!4v1724865547060!5m2!1ses!2sgt"
            width="75%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
        <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/0a94391b-3298-403a-8707-52b9c9bed1c2.jpeg"
            style={{ width: "60%", loading: "lazy" }}
          />
        </Grid>
      </Grid>
      <ImageList cols={5}>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/34a53c64-ee0e-4485-aad9-1a3ee94c555c.png`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`	https://escuela-bancaria.s3.us-east-2.amazonaws.com/ef7a9ad9-ff2c-4b29-97ec-9156ddf1d64e.png`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/a8923501-0214-4791-96a8-21c9a7daad98.png`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/95f9f0c2-a42c-462c-872f-d20c7e97f356.png`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
        <ImageListItem>
          <img
            src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/dae9df31-05d5-43cb-98f5-21e7eabe04a1.png`}
            alt="corain-img"
            style={{ width: "100%", objectFit: "cover" }}
            loading="lazy"
          />
        </ImageListItem>
      </ImageList>
      <Box
        style={{}}
        sx={{
          width: "100%",
          height: { lg: "40vh", md: "20vh", xs: "5vh" },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <video
          autoPlay
          muted
          playsInline
          loop
          style={{
            width: "100vw",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1716573726.mp4"
            type="video/mp4"
          />
        </video>
      </Box>
      <View
        evt="2476c5c7a9e9396e34e962722484b4cc7b9d58bc90bdc08a6f98018a40b246ca"
        pais="COSTA RICA"
      />
    </Stack>
  );
};

export default CR;
